import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"

// import HeroPage from "../components/views/hero-page"
import Gallery from "../components/views/gallery"

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="Our Services" img={`/img/slider1.jpg`} />

      <div style={{ marginTop: "100px", marginBottom: "50px" }}>
        <Container>
          <Row>
            <Col>
              <h3 className="section-title mb-5">Services</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <h4>Men-shave haircut</h4>
                <p>Hairstyle line,</p>
                <p>Hot steam towel razor mustache,</p>
                <p>Ball head high-in products cover gray,</p>
                <p>Hair skin care, and treatment.</p>
                <p>Chemical treatment-Curl,</p>
                <p>Relaxer,</p>
                <p>
                  Texturize, shampoo, conditioner, hot oil treatment, twist,
                  Dred-locks
                </p>
                <h4>Kids - haircut</h4>
                <p>
                  Fro-hawks, mo-hawks, Tappers, Fades, Designs, Twist,
                  Dred-locks
                </p>
                <h4>Women {`&`} girls</h4>
                <p>
                  Press {`&`} Curl, chemical relaxer, Color, Flat-Iron, Shampoo,
                  Blow-dry, Haircut style, Twist, Dred-locks
                </p>
                <p>
                  Roller-set, Wet-set, Finger-waves, Wet-waves, Shirley's Style
                </p>

                <div className="text-center" style={{ marginTop: "50px" }}>
                  <h3>
                    I look forward to connecting with you and please reach me
                    out if you have any questions about the services I offered.
                  </h3>
                  <a
                    className="btn btn-primary btn-lg mt-3"
                    href={`tel:${data.site.siteMetadata.phone}`}
                  >
                    {data.site.siteMetadata.phone}
                  </a>
                </div>
                <div className="section">
                  <Gallery />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ServicesPageQuery {
    site {
      siteMetadata {
        title
        description
        address
        phone
      }
    }
  }
`
