import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Gallery from "react-grid-gallery"

const IMAGES = [
  {
    src: "/img/gallery/1.jpeg",
    thumbnail: "/img/gallery/1.jpeg",
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: "/img/gallery/2.jpeg",
    thumbnail: "/img/gallery/2.jpeg",
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: "/img/gallery/3.jpeg",
    thumbnail: "/img/gallery/3.jpeg",
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: "/img/gallery/4.jpeg",
    thumbnail: "/img/gallery/4.jpeg",
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: "/img/gallery/5.jpeg",
    thumbnail: "/img/gallery/5.jpeg",
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: "/img/gallery/6.jpeg",
    thumbnail: "/img/gallery/6.jpeg",
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: "/img/gallery/7.jpeg",
    thumbnail: "/img/gallery/7.jpeg",
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: "/img/gallery/8.jpeg",
    thumbnail: "/img/gallery/8.jpeg",
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: "/img/gallery/9.jpeg",
    thumbnail: "/img/gallery/9.jpeg",
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: "/img/gallery/10.jpeg",
    thumbnail: "/img/gallery/10.jpeg",
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: "/img/gallery/11.jpeg",
    thumbnail: "/img/gallery/11.jpeg",
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
  {
    src: "/img/gallery/12.jpeg",
    thumbnail: "/img/gallery/12.jpeg",
    thumbnailWidth: 300,
    thumbnailHeight: 300,
  },
]

export default () => {
  return (
    <>
      <style>{`
        .ReactGridGallery_tile {
            border: solid 10px #fff;
        }
           
            @media(min-width: 768px) {
                .ReactGridGallery_tile {
                    width: 33.33333% !important;
                    overflow: hidden;
                }
                .ReactGridGallery_tile-viewport {
                    height: 200px !important;
                }
            }
             @media(min-width: 992px) {
              .ReactGridGallery_tile {
                    width: 33.33333% !important;
                    overflow: hidden;
              }
              .ReactGridGallery_tile-viewport {
                    height: 300px !important;
                }
            }
            @media(max-width: 767px) {
                .ReactGridGallery_tile {
                    width: 50% !important;
                }
                .ReactGridGallery_tile-viewport {
                    height: 200px !important;
                }
            }
            .ReactGridGallery_tile-viewport {
                width: 100% !important;
                position: relative;
            }
            .ReactGridGallery_tile-viewport>img {
                width: 108% !important;
                height: auto !important;
                position: absolute;
                left: 0;
                top: 0;
                transition: transform .2s;
            }
            .ReactGridGallery_tile-viewport img:hover {
                -ms-transform: scale(1.1); /* IE 9 */
                -webkit-transform: scale(1.1); /* Safari 3-8 */
                transform: scale(1.1);
            }
        `}</style>
      <Container>
        <Row>
          <Col className="text-center">
            <h2 className="section-title mb-5">Gallery</h2>
            <div style={{ marginLeft: "-10px", marginRight: "-10px" }}>
              <Gallery images={IMAGES} margin={0} />
            </div>

            <div style={{ clear: "both" }}></div>
          </Col>
        </Row>
      </Container>
    </>
  )
}
